.btnSection {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  gap: 1rem;
  color: var(--secondary-color);
}

.icon {
  height: 25px;
  width: 25px;
  filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
    brightness(71%) contrast(73%);
  cursor: pointer;

  &:hover {
    filter: invert(51%) sepia(69%) saturate(335%) hue-rotate(99deg)
      brightness(91%) contrast(93%);
  }
}
